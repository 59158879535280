// palarang
import $ from 'jquery';
import jQuery from 'jquery';

function calc() {
    var invoicecost = $('#invoicecost').val();
    var gstPlus;
    if ($('input[name=gstTax]:checked').val() == "gst") {
        gstPlus = (Number(invoicecost) * 1.1).toFixed(2);
    } else {
        gstPlus = (Number(invoicecost)).toFixed(2);
    }
    $('#gstPlus').val(gstPlus);

    calcMarkup(100, 1, '0');
    calcMarkup(180, 1, '180');
    calcMarkup(200, 2, '200');
    calcMarkup(205, 1, '205');
    calcMarkup(215, .5, '215');
    calcMarkup(225, .25, '225_250');
    calcMarkup(225, .15, '225_150');
    calcMarkup(225, .1, '225_100');
    calcMarkup(225, .08, '225_80');
    calcMarkup(225, .05, '225_50');
    calcMarkup(225, .02, '225_20');
    calcMarkup(355, 2, '355_2');
    calcMarkup(355, 1, '355_1');
    calcMarkup(355, .5, '355_05');
    calcMarkup(355, .25, '355_250');
    calcMarkup(355, .15, '355_150');
    calcMarkup(355, .1, '355_100');
    calcMarkup(355, .08, '355_80');
    calcMarkup(355, .05, '355_50');
    calcMarkup(355, .02, '355_20');
}

function calcMarkup(markup, kg, result) {
    var gstPlus = $('#gstPlus').val();
    var weight = $('#weight').val();
    var freight = $('#freight').val();
    var mu = (Math.ceil((((Number(gstPlus / weight) + Number(freight)) * markup / 100) * kg) * 20) / 20).toFixed(2);
    $('#result' + result).val(mu);
}

$(function() {
    $('#calculate2').on('click', function() {
        var invoicecost = $('#invoicecost').val();
		console.log(invoicecost);
        var weight = $('#weight').val();
        var edit = true;
        if (isNaN(invoicecost) || invoicecost == "") {
            alert('invoice cost is missing or not a number');
            edit = false;
        }
        if (isNaN(weight)) {
            alert('weight is missing or not a number');
            edit = false;
        }
        if (edit)
            calc();
    });
});

// GST listener 2
$("input[name=gstTax]:radio").click(function() {
    if ($('input[name=gstTax]:checked').val() == "gst") {
        var invoicecost = $('#invoicecost').val();
        var gstPlus = (Number(invoicecost) * 1.1).toFixed(2);
        $('#gstPlus').val(gstPlus);


    } else if ($('input[name=gstTax]:checked').val() == "nogst") {
        var invoicecost = $('#invoicecost').val();
        var gstPlus = (Number(invoicecost)).toFixed(2);
        $('#gstPlus').val(gstPlus);
    }
});
