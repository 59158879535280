// palarang
import $ from 'jquery';
import jQuery from 'jquery';

 $(function()
     {
     function scroll_if_anchor(href)
         {
         href = typeof(href) == "string" ? href : $(this).attr("href");

         // If href missing, ignore
         if(!href)
             return;

         // You could easily calculate this dynamically if you prefer
         var fromTop = 65;

         // If our Href points to a valid, non-empty anchor, and is on the same page (e.g. #foo)
         // Legacy jQuery and IE7 may have issues: http://stackoverflow.com/q/1593174
         var $target = $(href);

         // Older browsers without pushState might flicker here, as they momentarily
         // jump to the wrong position (IE < 10)
         if($target.length)
             {
             $('html, body').animate({ scrollTop: $target.offset().top - fromTop });
             if (history && "pushState" in history)
                 {
                 history.pushState({}, document.title, window.location.pathname + href);
                 return false;
                 }
             }
         }
     $('#content').on("click", "a[href^='#']",scroll_if_anchor);
     scroll_if_anchor(window.location.hash);
     });
