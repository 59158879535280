import $ from 'jquery';
import jQuery from 'jquery';
$(document).ready(function()
	{
	$('.bizthumbnail').showLargeImage(); 
	$('.moreinfo').hideInfo();
	});
 
// avatar loader
$(document).ready(function() {
	
    var readURL = function(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $('.profile-pic').attr('src', e.target.result);
            }
    
            reader.readAsDataURL(input.files[0]);
        }
    }
   
    $(".file-upload").on('change', function(){
        readURL(this);
    });
    
    $(".upload-button").on('click', function() {
       $(".file-upload").click();
    });
});

// comment form inner opener
//$("#comment_button").click(function(){
//        $(".form_inner").show('slow');
//   });
//$("#comment_text").click(function(){
//        $(".form_inner").show('slow');
//    });

// read more
$(document).ready(function() {
  $("#toggle").click(function() {
    var elem = $("#toggle").text();
    if (elem == "Read More") {
      //Stuff to do when btn is in the read more state
      $("#toggle").text("Read Less");
      $("#text").slideDown();
    } else {
      //Stuff to do when btn is in the read less state
      $("#toggle").text("Read More");
      $("#text").slideUp();
    }
  });
});

// hidefield toggle
$(function()
    {
    $('.hidefield').each(function()
        {
        var grp = $(this).data('target');
       	$(this).find('input').each(function()
        	{
					var targetid = $(this).attr('id');
					var target = $('.'+targetid);
					$(this).change(function()
							{
							$('.'+grp).hide();
							target.show('slow');
							return true;
							});
					if ( ! $(this).is(':checked'))
						 target.hide();
					});
				});
    }); 

$(function()
	{
	$('.toggle-view li').click(function ()
		{
		var text = $(this).children('div.ans');
		if (text.is(':hidden'))
			{
			text.slideDown('200');
			$(this).children('span').html('-');        
			}
		else
			{
			text.slideUp('200');
			$(this).children('span').html('+');        
			}
    });
	});

$(function()
	{
	$('a[href*="#"]').not('.top-bar a').not('.accordion a').not('.tabs a').not('.orbit-container a').not('.reveal-link').not('.clearing-assembled a').click(function()
		{
		var adjustOffset = 40;
		//var adjustOffset = 0;
		if (this.hash.length == 0)
			{
			var targetOffset = - adjustOffset;
			$('html,body').animate({scrollTop: targetOffset}, 1000);
			return false;
			}
		var $target = $(this.hash);
		$target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
		if ($target.length)
			{
			var targetOffset = $target.offset().top - adjustOffset;
			$('html,body').animate({scrollTop: targetOffset}, 1000);
			return false;
			}
		});
	});

// moreinfo
(function($)
	{
	$.fn.hideInfo = function()
		{
		return this.each(function()
			{
			var self = $(this);
			var showtext = 'Read more...';
			var hidetext = 'Read less...';
			var title = self.attr('title');
			if (title)
				{
				showtext = 'Show ' + title;
				hidetext = 'Hide ' + title;
				}
			var lnk = $('<a class="minfo" href="#">' + showtext + '</a>');
			lnk.click(function()
				{
			  self.slideToggle('slow', function()
					{
					if ($(this).is(":visible"))
						lnk.text(hidetext);
			    else
						lnk.text(showtext);
					//setTall();
			  	});
				return false;
				});
			var p = $('<p/>');
			p.append(lnk);
			p.insertAfter(self);
			self.hide();
			});
		}
	})(jQuery);

(function($)
	{
	$.fn.showLargeImage = function()
		{
		$(document).keypress(function(e)
			{
			if(e.keyCode==27)  
				removeLightbox();
			});
		return this.each(function()
			{
			var a = $('<a class="lightbox"/>');
			var src = $(this).attr('src');
			var dot = src.lastIndexOf('.');
			src = src.substr(0, dot) + '-large' + src.substr(dot);
			a.attr('href', src);
			a.click(function(e)
				{
				$('body').css('overflow-y', 'hidden');

				$('<div id="overlay"></div>')
					.css('top', $(document).scrollTop())
					.css('opacity', '0')
					.animate({'opacity': '0.5'}, 'slow')
					.appendTo('body');

				$('<div id="lightbox"></div>')
					.hide()
					.appendTo('body');

				$('<img title="Click here to close"/>')
					.attr('src', $(this).attr('href'))
					.on('load', function()
						{
						positionLightboxImage();
						})
					.on('click', function()
						{
						removeLightbox();
						})
					.appendTo('#lightbox');

				return false;
				});
			$(this).wrap(a);
			});
		function positionLightboxImage()
			{
			var top = ($(window).height() - $('#lightbox').height()) / 2;
			var left = ($(window).width() - $('#lightbox').width()) / 2;
			var closebutton = $('<img src="/images/close.png" width="30" height="30" alt="close" style="position: absolute; top: 0px; right: 0px;"/>');
			closebutton.click(function()
				{
				removeLightbox();
				});
			$('#lightbox')
				.append(closebutton)
				.css({'top': top + $(document).scrollTop(), 'left': left})
				.fadeIn();
			}
		function removeLightbox()
			{
			$('#overlay, #lightbox')
				.fadeOut('slow', function()
					{
					$(this).remove();
					$('body').css('overflow-y', 'auto');
					});
			}
		}
	})(jQuery);

